import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {randomString} from "../../core/RandomAlphaNumericString";
const crypto = require("crypto");

// const liff = window.liff;
const lineAuthentication = async(cb) => {
    // const liffId = process.env.REACT_APP_LINE_LIFF_ID;
    // await liff.init({ liffId: liffId, withLoginOnExternalBrowser: true }).catch(err=>{throw err});
    // if (liff.isLoggedIn()) {
    //     const accessToken = await liff.getAccessToken();
    //     return cb(accessToken);
    // }else{
        // liff.login(); //external browser can not use liff.login(); for verify token
        const clientId = process.env.REACT_APP_LINE_CLIENT_ID;
        const redirectUri = `${process.env.REACT_APP_BASE_URL}/login`;
        const state = Math.floor(100000 + Math.random() * 900000);
        const code_verifier = randomString(43);
        const code_challenge = base64UrlEncode(crypto
            .createHash('sha256')
            .update(code_verifier)
            .digest('base64'));

        localStorage.setItem('code_verifier', code_verifier);

        const url =
            'https://access.line.me/oauth2/v2.1/authorize?response_type=code' +
            '&client_id=' +
            clientId +
            '&redirect_uri=' +
            redirectUri +
            '&state=' +
            state +
            '&code_challenge=' +
            code_challenge +
            '&code_challenge_method=S256' +
            '&scope=profile';

        window.location.href = url;
    // }
};

function base64UrlEncode(str) {
    return str
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

export default function SignIn() {
    const history = useHistory();
    const { mode, key } = useParams();

    useEffect(() => {
        console.log('[SignIn]');
        lineAuthentication(accessToken=>{
            if(accessToken){

                const requestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({accessToken: accessToken, redirectUri: process.env.REACT_APP_BASE_URL+'/login'})
                };

                const url = process.env.REACT_APP_API_BASE_URL + '/oauth/line/token';
                fetch(url, requestOptions)
                    .then(results => results.json())
                    .then(data => {
                        if (data.token) {
                            localStorage.setItem('token', data.token);
                            let rider = {
                                displayName: data.displayName,
                                pictureUrl: data.pictureUrl
                            };
                            localStorage.setItem('rider', JSON.stringify(rider));
                            history.push('/home');
                        } else {
                            localStorage.removeItem('token');
                            history.push('/login');
                        }
                    });
            }
        });
    }, [history, mode, key]);

    return (
        <div>
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20} />
            </Box>
        </div>
    )
}